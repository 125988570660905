<template>
  <div v-if="localTracksToShow">
    <h2>Deine gespeicherten Tracks zum Playlist erstellen</h2>
    <button @click="createPlaylistWithAllTracks">Alle Tracks in Playlist</button>
    <button @click="createPlaylistWithFavorites">Favoriten in Playlist</button>

    <div class="track-list">
      <div v-for="(track, index) in localTracksToShow" :key="track.id" class="track-card" draggable="true"
           @dragstart="onDragStart(track, index)"
           @dragover.prevent
           @drop="onDrop(index)">
        <div class="card-body" :style="{ backgroundImage: `url(${track.album.images[0].url})` }">
          <i @click.prevent="updateTrack(track)"
             :class="track.is_favorite ? 'fas fa-star favorite' : 'far fa-star not-favorite'"
             class="star-icon">
          </i>
          <button @click.prevent="removeTrackFromApi(track)" class="api-send-button"><i class="fas fa-minus-circle"></i></button>
          <button v-if="track.preview_url" @click.prevent="playTrack(track.preview_url)" class="play-button">
            <i v-if="currentPreviewUrl !== track.preview_url || (audioPlayer.value && audioPlayer.value.paused)" class="fa fa-play"></i>
            <i v-else class="fa fa-pause"></i>
          </button>
          <h3 class="track-title">
            <span class="white-background">{{ index + 1 }}. <a :href="track.external_urls.spotify" target="_blank">{{ track.name }}</a></span>
          </h3>
          <p class="track-artist">
            <span class="white-background">
              <template v-for="(artist, index) in track.artists" :key="artist.id">
                <a :href="artist.external_urls.spotify" target="_blank">
                  {{ artist.name }}
                </a>
                <span v-if="index < track.artists.length - 1">, </span>
              </template>
            </span>
          </p>
          <p class="track-album">
            <span class="white-background"><a :href="track.album.external_urls.spotify" target="_blank">{{ track.album.name }}</a></span>
          </p>
        </div>
      </div>
    </div>
    <audio ref="audioPlayer" autoplay></audio>
  </div>
</template>

<script>
import {onUnmounted, ref, watch} from 'vue';
import {getAuthTokenFromCookie} from "@/services/cookieService";
import axios from "axios";
import {addTracksToPlaylist} from "@/services/spotifyService";

export default {
  name: "SavedToShow",
  props: {
    tracksToShow: Array,
    displayTerm: String,
    currentTerm: String
  },
  setup(props) {
    const currentPreviewUrl = ref(null);
    const audioPlayer = ref(null);
    const localTracksToShow = ref(props.tracksToShow);
    let timeoutId = null

    const baseUrl = process.env.VUE_APP_SPRING_API_URL;

    const playTrack = (previewUrl) => {
      // Vorherigen Timeout abbrechen, falls vorhanden
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (currentPreviewUrl.value === previewUrl && !audioPlayer.value.paused) {
        audioPlayer.value.pause();
        currentPreviewUrl.value = null;
      } else {
        audioPlayer.value.src = previewUrl;
        audioPlayer.value.play();
        currentPreviewUrl.value = previewUrl;

        // Timeout einstellen, um nach 30 Sekunden zu pausieren und currentPreviewUrl zurückzusetzen
        timeoutId = setTimeout(() => {
          audioPlayer.value.pause();
          currentPreviewUrl.value = null;
        }, 29000); // 29 Sekunden weil sonst komisch laggy/verzögert (wegen 30 Sekunden Timeout)
      }
    };

    watch(() => props.tracksToShow, (newTracks) => {
      if (newTracks) {
        localTracksToShow.value = [...newTracks];
      }
    }, { deep: true });

    onUnmounted(() => {
      if (audioPlayer.value) {
        audioPlayer.value.pause();
        audioPlayer.value.src = '';
      }
    });


    const removeTrackFromApi = async (track) => {
      if (!track) {
        console.error('Kein Track-Objekt zum Senden vorhanden');
        return;
      }

      try {
        const authToken = getAuthTokenFromCookie();
        if (!authToken) {
          console.error('Auth-Token nicht gefunden');
          return;
        }

        let song_id = track.id;
        const response = await axios.delete(`${baseUrl}/api/songs/delete/${song_id}`, {
          headers: {
            'Authorization': authToken
          }
        });

        if (response.status === 200) {
          console.log('Track erfolgreich gesendet');
          localTracksToShow.value = localTracksToShow.value.filter(t => t.id !== track.id);
          await updatePlaylistOrder();
        } else {
          console.error('Fehler beim Senden des Tracks:', response.status);
        }
      } catch (error) {
        console.error('Fehler beim Senden des Tracks:', error);
      }
    };



    const updateTrack = async (track) => {
      if (!track) {
        console.error('Kein Track-Objekt zum Senden vorhanden');
        return;
      }

      try {
        const authToken = getAuthTokenFromCookie();
        if (!authToken) {
          console.error('Auth-Token nicht gefunden');
          return;
        }

        // Logge die Daten, die gesendet werden, um zu überprüfen, ob sie korrekt sind
        console.log("Aktualisiere Track:", track.id, "Neuer Favoritenstatus:", !track.is_favorite);

        const response = await axios.put(`${baseUrl}/api/songs`, JSON.stringify({
          jsonData: track,
          song_id: track.id,
          favorite: !track.is_favorite
        }), {
          headers: {
            'Authorization': authToken,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('Track erfolgreich aktualisiert');
          const index = localTracksToShow.value.findIndex(t => t.id === track.id);
          if (index !== -1) {
            localTracksToShow.value[index] = { ...track, is_favorite: !track.is_favorite };
          }
        } else {
          console.error('Fehler beim Aktualisieren des Tracks:', response.status);
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Tracks:', error);
      }
    };

    const createPlaylistWithAllTracks = async () => {
      try {
        await updatePlaylistOrder();
        const response = await axios.get(`${baseUrl}/api/songs/getAllSavedTrackIDs`, {
          headers: {
            'Authorization': `${getAuthTokenFromCookie()}`
          }
        });
        await addTracksToPlaylist(response.data);
        alert('Playlist mit allen Tracks wurde erfolgreich gespeichert.');
      } catch (error) {
        console.error("Fehler beim Speichern der Playlist mit allen Tracks:", error);
        alert('Fehler beim Speichern der Playlist mit allen Tracks.');
      }
    };


    const createPlaylistWithFavorites = async () => {
      try {
        await updatePlaylistOrder();
        const response = await axios.get(`${baseUrl}/api/songs/getAllFavoriteTrackIDs`, {
          headers: {
            'Authorization': `${getAuthTokenFromCookie()}`
          }
        });
        await addTracksToPlaylist(response.data);
        alert('Playlist mit Favoriten wurde erfolgreich gespeichert.');
      } catch (error) {
        console.error("Fehler beim Speichern der Playlist mit Favoriten:", error);
        alert('Fehler beim Speichern der Playlist mit Favoriten.');
      }
    };

    const draggedItem = ref(null);

    const onDragStart = (track, index) => {
      draggedItem.value = { track, index };
    };

    const onDrop = async (targetIndex) => {
      if (draggedItem.value && targetIndex !== draggedItem.value.index) {
        const updatedTracks = [...localTracksToShow.value];
        const [removedItem] = updatedTracks.splice(draggedItem.value.index, 1);
        updatedTracks.splice(targetIndex, 0, removedItem);
        localTracksToShow.value = updatedTracks;

        // refreshing the order in db after drag and drop
        await updatePlaylistOrder();
      }
    };


    const updatePlaylistOrder = async () => {
      const trackOrderUpdates = localTracksToShow.value.map((track, index) => ({
        song_id: track.id,
        playlist_index: index
      }));

      try {
        await axios.put(`${baseUrl}/api/songs/update-order`, trackOrderUpdates, {
          headers: { 'Authorization': getAuthTokenFromCookie() }
        });
        console.log('Reihenfolge erfolgreich aktualisiert');
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Reihenfolge:', error);
      }
    };



    return { currentPreviewUrl, audioPlayer, playTrack, removeTrackFromApi, updateTrack, localTracksToShow, createPlaylistWithAllTracks, createPlaylistWithFavorites, onDragStart, onDrop};
  }
}




</script>

<style scoped>

.star-icon {
  font-size: 1.7em;
  position: absolute;
  top: 10px; /* Anpassen, um die Position zu ändern */
  left: 10px; /* Anpassen, um die Position zu ändern */
  cursor: pointer; /* Ändert den Cursor, um Klickbarkeit anzudeuten */
  color: grey; /* Standardfarbe, die immer angewendet wird */
}

.favorite {
  color: gold; /* Farbe für ausgefüllte Sterne */
}

.not-favorite {
  /* Weitere Stile, falls erforderlich */
}

.api-send-button {
  background: transparent;
  border: none;
  color: white; /* Helle Farbe */
  text-shadow: 0 0 5px black; /* Dunkler Schatten */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.7em;
}

.api-send-button:hover {
  color: darkolivegreen; /* Dunklere Farbe beim Hover */
  text-shadow: 0 0 10px rgba(0,0,0,0.5); /* Schatten hinzufügen */
  transform: scale(1.15); /* Vergrößern um 15% */
  transition: transform 0.3s ease, color 0.3s ease, text-shadow 0.3s ease;
}

</style>
