<template>
  <div v-if="tracksToShow">
    <h2>{{ displayTerm }}</h2>
    <div class="track-list">
      <div v-for="(track, index) in tracksToShow" :key="index" class="track-card">
        <div class="card-body" :style="{ backgroundImage: `url(${track.album.images[0].url})` }">
          <button @click.prevent="sendTrackToApi(track, index)" class="api-send-button"><i class="fas fa-plus-circle"></i></button>
          <button v-if="track.preview_url" @click.prevent="playTrack(track.preview_url)" class="play-button">
            <i v-if="currentPreviewUrl !== track.preview_url || (audioPlayer.value && audioPlayer.value.paused)" class="fa fa-play"></i>
            <i v-else class="fa fa-pause"></i>
          </button>
          <h3 class="track-title">
              <span class="white-background">{{ track.rank ? track.rank + '. ' : '' }}<a
                  :href="track.external_urls.spotify" target="_blank">{{ track.name }}</a></span>
          </h3>
          <p class="track-artist">
            <span class="white-background">
              <template v-for="(artist, index) in track.artists" :key="artist.id">
                <a :href="artist.external_urls.spotify" target="_blank">
                  {{ artist.name }}
                </a>
                <span v-if="index < track.artists.length - 1">, </span>
              </template>
            </span>
          </p>
          <p class="track-album">
            <span class="white-background"><a :href="track.album.external_urls.spotify" target="_blank">{{ track.album.name }}</a></span>
          </p>
          <p v-if="!track.rank && track.played_at" class="track-played-at">{{ formatToLocalTime(track.played_at) }}</p>        </div>
      </div>
    </div>
    <audio ref="audioPlayer" autoplay></audio>
  </div>
</template>

<script>
import {computed, onUnmounted, ref} from 'vue';
import {getAuthTokenFromCookie} from "@/services/cookieService";
import axios from "axios";

export default {
  name: "SongsToShow",
  props: {
    tracksToShow: Array,
    currentTerm: String
  },
  setup(props) {
    const currentPreviewUrl = ref(null);
    const audioPlayer = ref(null);
    let timeoutId = null

    const baseUrl = process.env.VUE_APP_SPRING_API_URL;

    const playTrack = (previewUrl) => {
      // Vorherigen Timeout abbrechen, falls vorhanden
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (currentPreviewUrl.value === previewUrl && !audioPlayer.value.paused) {
        audioPlayer.value.pause();
        currentPreviewUrl.value = null;
      } else {
        audioPlayer.value.src = previewUrl;
        audioPlayer.value.play();
        currentPreviewUrl.value = previewUrl;

        // Timeout einstellen, um nach 30 Sekunden zu pausieren und currentPreviewUrl zurückzusetzen
        timeoutId = setTimeout(() => {
          audioPlayer.value.pause();
          currentPreviewUrl.value = null;
        }, 29000); // 29 Sekunden weil sonst komisch laggy/verzögert (wegen 30 Sekunden Timeout)
      }
    };

    onUnmounted(() => {
      if (audioPlayer.value) {
        audioPlayer.value.pause();
        audioPlayer.value.src = '';
      }
    });


    const sendTrackToApi = async (track) => {
      if (!track) {
        console.error('Kein Track-Objekt zum Senden vorhanden');
        return;
      }

      try {
        const authToken = getAuthTokenFromCookie();
        if (!authToken) {
          console.error('Auth-Token nicht gefunden');
          return;
        }

        const response = await axios.post(`${baseUrl}/api/songs`, JSON.stringify({
          jsonData: track,
          song_id: track.id
        }), {
          headers: {
            'Authorization': authToken,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('Track erfolgreich gesendet');
        } else {
          console.error('Fehler beim Senden des Tracks:', response.status);
        }
      } catch (error) {
        console.error('Fehler beim Senden des Tracks:', error);
      }
    };

    // Hier verwenden wir ein termMappings Objekt, um die currentTerm Werte den entsprechenden Strings zuzuordnen. Der Zugriff auf die Werte im Objekt ist oft schneller und einfacher zu verstehen als ein switch-Statement. Der Fallback-Wert (mit dem || Operator) wird zurückgegeben, wenn currentTerm.value nicht im termMappings Objekt gefunden wird. Annotation was created by me using ChatGPT
    const termMappings = {
      short_term: 'Deine Top-Tracks des letzten Monats',
      medium_term: 'Deine Top-Tracks der letzten drei Monate',
      long_term: 'Deine Top-Tracks des letzten Jahres',
      recent_tracks: 'Zuletzt gehört'
    };

    /**
     * Ermittelt den Anzeigetext basierend auf dem aktuellen Zeitraum aus termMappings.
     * Gibt einen Standardtext zurück, wenn der Zeitraum nicht erkannt wird.
     * @returns {string} Den zum aktuellen Begriff gehörenden Anzeigetext oder einen Standardtext.
     */
    const displayTerm = computed(() => {
      return termMappings[props.currentTerm] || 'Bruder irgendwas stimmt nicht, du solltest diesen String niemals sehen';
    });

    const formatToLocalTime = (isoDateTime) => {
      if (!isoDateTime) return '';

      const date = new Date(isoDateTime);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${hours}:${minutes}`;
    };

    return { currentPreviewUrl, audioPlayer, playTrack, sendTrackToApi, displayTerm, formatToLocalTime};
  }
}
</script>

<style scoped>

.api-send-button {
  background: transparent;
  border: none;
  color: white; /* Helle Farbe */
  text-shadow: 0 0 5px black; /* Dunkler Schatten */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.7em;
}

.api-send-button:hover {
  color: darkolivegreen; /* Dunklere Farbe beim Hover */
  text-shadow: 0 0 10px rgba(0,0,0,0.5); /* Schatten hinzufügen */
  transform: scale(1.15); /* Vergrößern um 15% */
  transition: transform 0.3s ease, color 0.3s ease, text-shadow 0.3s ease;
}

</style>
