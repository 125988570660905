<template>
  <div v-if="artistsToShow">
    <h2>{{ displayTerm }}</h2>
    <div class="artist-list">
      <div v-for="(artist, index) in artistsToShow" :key="index" class="artist-card">
        <div class="card-body" :style="{ backgroundImage: `url(${artist.images[0].url})` }">
          <h3 class="artist-title">
            <span class="white-background">{{ artist.rank ? artist.rank + '. ' : '' }}
            <a :href="artist.external_urls.spotify" target="_blank">{{ artist.name }}</a></span>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computed} from "vue";

export default {
  name: "ArtistsToShow",
  props: {
    artistsToShow: Array,
    currentTerm: String
  },
  setup(props) {


    // Hier verwenden wir ein termMappings Objekt, um die currentTerm Werte den entsprechenden Strings zuzuordnen. Der Zugriff auf die Werte im Objekt ist oft schneller und einfacher zu verstehen als ein switch-Statement. Der Fallback-Wert (mit dem || Operator) wird zurückgegeben, wenn currentTerm.value nicht im termMappings Objekt gefunden wird. Annotation was created by me using ChatGPT
    const termMappings = {
      short_term: 'Deine Top-Artists des letzten Monats',
      medium_term: 'Deine Top-Artists der letzten drei Monate',
      long_term: 'Deine Top-Artists des letzten Jahres',
    };


    /**
     * Ermittelt den Anzeigetext basierend auf dem aktuellen Zeitraum aus termMappings.
     * Gibt einen Standardtext zurück, wenn der Zeitraum nicht erkannt wird.
     * @returns {string} Den zum aktuellen Begriff gehörenden Anzeigetext oder einen Standardtext.
     */
    const displayTerm = computed(() => {
      return termMappings[props.currentTerm] || 'Bruder irgendwas stimmt nicht, du solltest diesen String niemals sehen';
    });
    return {displayTerm};
  }
}
</script>

<style scoped>
</style>
