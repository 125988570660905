<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <button @click="loginToSpotify">Bei Spotify einloggen</button>
    <button @click="changeTimeframe('short_term')">Short Term</button>
    <button @click="changeTimeframe('medium_term')">Medium Term</button>
    <button @click="changeTimeframe('long_term')">Long Term</button>
    <button @click="changeToRecentTracks(50)">Zuletzt gehört</button>
    <button @click="navigateToLogin">Login</button>
    <button @click="showTracks">Top Tracks anzeigen</button>
    <button @click="showArtists">Top Artists anzeigen</button>
    <button @click="fetchSavedTracks()">Saved-Tracks</button>
    <SongsToShow
        v-show="currentView === 'tracks' && tracksToShow"
        :tracksToShow="tracksToShow"
        :currentTerm="currentTerm"
    />

    <ArtistsToShow
        v-show="currentView === 'artists' && artistsToShow"
        :artistsToShow="artistsToShow"
        :currentTerm="currentTerm"
    />
    <SavedToShow
        v-show="currentView === 'saved' && tracksToShow"
        :tracksToShow="tracksToShow"
        :currentTerm="currentTerm"
    />

    <router-view>
    </router-view>
  </div>
</template>

<script>


import {onMounted, ref, watch, provide } from "vue";
import SongsToShow from "./components/SongsToShow.vue";
import ArtistsToShow from "@/components/ArtistsToShow.vue";
import { useRouter} from "vue-router";
import axios from 'axios';
import './styles.css';
import {getAuthTokenFromCookie} from "@/services/cookieService";
import {fetchRecentTracks, fetchTopArtists, fetchTopTracks} from "@/services/spotifyService";
import SavedToShow from "@/components/SavedToShow.vue";


export default {
  name: "App",
  computed: {
  },
  components: {
    SavedToShow,
    SongsToShow, ArtistsToShow
  },
  setup() {

    let currentPreviewUrl = ref(null);


    const audioPlayer = ref(null);
    const router = useRouter();

    const tracksToShow = ref([]);
    const artistsToShow = ref([]);
    const currentView = ref('tracks'); // Möglichkeiten: 'tracks', 'artists'
    const recentBufferView = ref('tracks');
    const setCurrentView = (view) => {
      currentView.value = view;
    };
    provide('setCurrentView', setCurrentView);
    const currentTerm = ref('medium_term');
    const spotifyBaseUrl = process.env.VUE_APP_SPOTIFY_API_URL;
    const springBaseUrl = process.env.VUE_APP_SPRING_API_URL;


    /**
     * Weiterleitung zur Spring Login-Seite.
     */
    const navigateToLogin = () => {
      console.log("Navigating to login page");
      currentView.value = 'none';
      router.push('/login');
    };


    onMounted(() => {
      // this makes sure that medium_term is loaded on page load so we dont have to click the button
      currentTerm.value = 'medium_term';
      loadData();
      try {
        axios.get(process.env.VUE_APP_SPRING_API_URL + '/api/songs/wakeup');
      } catch (error) {
        console.error('Error waking up Spring backend:', error);
      }
      try {
        axios.get(process.env.VUE_APP_SPOTIFY_API_URL + '/wakeup');
      } catch (error) {
        console.error('Error waking up Python backend:', error);
      }
    });


    /**
     * Startet den OAuth-Prozess durch Weiterleitung zur Spotify-Login-URL.
     * Bei einem Fehler während des Prozesses wird eine Fehlermeldung in der Konsole angezeigt.
     * @async
     */
    const loginToSpotify = async () => {
      try {
        console.log(`Forwarding to ${spotifyBaseUrl}/login`)
        window.location.href = `https://flask-api.lucafriedrich.com/login`;
      } catch (error) {
        console.error("Error during the OAuth process:", error);
      }
    };



    // not sure yet if we need to pass some args. TimeFrame shouldnt be used for this. we need some identifier
    // might be able to extract via SQL GROUP BY userid. To implement at a later stage.
    const fetchSavedTracks = async () => {

      try {
        console.log('Fetching your saved tracks');

        const authToken = getAuthTokenFromCookie();

        // Hier prüfen wir den Wert des Tokens, den wir aus den Cookies extrahiert haben.
        console.log('Token extracted from cookie:', authToken);

        const response = await axios.get(`${springBaseUrl}/api/songs/getAllSavedTracks`, {
          headers: {
            'Authorization': `${authToken}`
          }
        });

        // Überprüfen des gesamten Antwortstatus
        console.log('Response status:', response.status);
        if (response.status !== 200) {
          console.log("Failed to fetch top tracks");
        }
        // clear the array before adding new tracks or else we will have duplicates in tracks when switching between timeframes
        if (currentView.value === 'saved') {
          return;
        }
        tracksToShow.value = [];
        recentBufferView.value = currentView.value;
        currentView.value = 'saved';
        // Zweites await ist notwendig, verstehe nicht wieso
        tracksToShow.value = await response.data;
        //autoPlayFirstTrack();
      } catch (error) {
        console.error("Error fetching top tracks:", error);
      }
    };

    /**
     * Ändert den Zeitraum der angezeigten Top Tracks und aktualisiert die Anzeige entsprechend.
     * Ruft auch die Methode fetchTopTracks() mit dem übergebenen Zeitraum auf, um die Daten erneut zu laden.
     *
     * @param {string} timeframe - Der neue Zeitraum ('short_term', 'medium_term', oder 'long_term').
     */
    const changeTimeframe = async (timeframe) => {
      console.log("Timeframe changed to:", timeframe);
      currentTerm.value = timeframe;
      if (document.getElementById('loginMarker')) {
        currentView.value = 'tracks';
        tracksToShow.value = []
      }
      await loadData();
    };

    const loadData = async () => {
      if (currentView.value === 'tracks') {
        if (currentView.value === 'recent_tracks') {
          currentView.value = recentBufferView.value;
          tracksToShow.value = await fetchRecentTracks(); // theoretically we could pass a limit here
        } else {
          tracksToShow.value = await fetchTopTracks(currentTerm.value);
        }
      } else if (currentView.value === 'artists') {
        artistsToShow.value = await fetchTopArtists(currentTerm.value);
      }
      else if (currentView.value === 'saved') {
        currentView.value = recentBufferView.value;
        if (currentView.value === 'tracks') {
          tracksToShow.value = [];
          tracksToShow.value = await fetchTopTracks(currentTerm.value);
        } else if (currentView.value === 'artists') {
          artistsToShow.value = await fetchTopArtists(currentTerm.value);
        }
      }
    };

    /**
     * Ändert die Anzeige auf die zuletzt gehörten Tracks und aktualisiert die Anzeige entsprechend.
     * Ruft auch die Methode fetchRecentTracks() mit dem übergebenen limit auf, um die Daten erneut zu laden.
     *
     * @param {number} limit - Die maximale Anzahl von Tracks, die abgerufen werden sollen.
     */
    const changeToRecentTracks = async (limit) => {
      console.log("changed to: recent tracks")
      currentTerm.value = 'recent_tracks';
      currentView.value = 'tracks';
      tracksToShow.value = await fetchRecentTracks(limit);
    };



    /**
     * Beobachtet Änderungen des aktuellen Zeitraums und protokolliert diese.
     *
     * @param {string} newValue - Der neue Wert von currentTerm.
     * @param {string} oldValue - Der vorherige Wert von currentTerm.
     */
    watch(currentTerm, (newValue, oldValue) => {
      console.log("Current term changed:", newValue, "from", oldValue);
    });

    /**
     * Spielt die Track-Vorschau über den Audio-Player ab oder pausiert sie, wenn sie bereits abgespielt wird.
     *
     * @param {string} previewUrl - Die URL der Track-Vorschau, die abgespielt oder pausiert werden soll.
     */
    const playTrack = (previewUrl) => {
      if (currentPreviewUrl.value === previewUrl && !audioPlayer.value.paused) {
        audioPlayer.value.pause();
      } else {
        audioPlayer.value.pause();
        audioPlayer.value.src = previewUrl;
        audioPlayer.value.play();
        currentPreviewUrl.value = previewUrl;
      }
    };

    /**
     * Spielt automatisch die Vorschau des ersten Tracks in der Liste `tracksToShow` ab, wenn die Liste nicht leer ist.
     */
    // const autoPlayFirstTrack = () => {
    //   if (tracksToShow.value.length > 0) {
    //     audioPlayer.value.src = tracksToShow.value[0].preview_url;
    //     audioPlayer.value.play();
    //     currentPreviewUrl.value = tracksToShow.value[0].preview_url;
    //   }
    // };

    const showTracks = async () => {
      // this logic solves the bug of clicking TopTracks when in recent tracks view thus not showing the top tracks
      currentView.value = 'tracks';
      if (currentTerm.value === 'recent_tracks') {
        currentTerm.value = 'medium_term';
      }
      await loadData();
    };

    const showArtists = async () => {
      // this logic solves the bug of clicking TopArtists when in recent tracks view thus not showing the top artists
      currentView.value = 'artists';
      if (currentTerm.value === 'recent_tracks') {
        currentTerm.value = 'medium_term';
      }
      await loadData();
    };

    return {
      navigateToLogin,
      tracksToShow,
      artistsToShow,
      fetchTopArtists,
      fetchSavedTracks,
      currentTerm,
      loginToSpotify,
      fetchTopTracks,
      changeTimeframe, // Neue Methode hinzugefügt
      playTrack, // Neue Methode hinzugefügt
      audioPlayer, // Ref hinzugefügt
      changeToRecentTracks, //toggles view to recent tracks as it does not work via displayTerm
      currentPreviewUrl, // Ref hinzugefügt
      showTracks,
      showArtists,
      currentView
    };
  },
};
</script>



