import { createRouter, createWebHistory } from 'vue-router';
// import App from '../App.vue';
import LoginToJavaBackend from "@/views/LoginToJavaBackend.vue";

const routes = [
    // {
    //     path: '/',
    //     name: 'Home',
    //     component: App
    // },
    {
        path: '/login',
        name: 'LoginToJavaBackend',
        component: LoginToJavaBackend
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
