import axios from 'axios';
import {getAuthTokenFromCookie, getSpotifyTokenFromCookie} from "@/services/cookieService";
const baseURL = process.env.VUE_APP_SPOTIFY_URL;
const springURL = process.env.VUE_APP_SPRING_API_URL;


/**
 * Ruft die Top-Tracks des Benutzers von einem Backend-Service ab, basierend auf einem spezifizierten Zeitraum.
 * Standardmäßig werden die Top-Tracks der letzten drei Monate abgerufen.
 *
 * @async
 * @param {string} [term='medium_term'] - Zeitraum für die Abfrage der Top-Tracks. Möglichkeiten sind 'short_term', 'medium_term' und 'long_term'.
 * @throws {Error} Wird ausgelöst, wenn es ein Problem beim Abrufen der Top-Tracks gibt.
 */
export const fetchTopTracks = async (term = 'medium_term') => {
    try {
        console.log('Fetching top tracks');

        const spotifyToken = getSpotifyTokenFromCookie();

        // Hier prüfen wir den Wert des Tokens, den wir aus den Cookies extrahiert haben.
        console.log('Token extracted from cookie:', spotifyToken);

        const response = await axios.get(`${baseURL}/top-tracks/${term}`, {
            headers: {
                'Authorization': `Bearer ${spotifyToken}`
            }
        });

        // Überprüfen des gesamten Antwortstatus
        console.log('Response status:', response.status);
        console.log('Response data, is this a unresolved promise?:', response.data);

        if (response.status !== 200) {
            throw new Error("Failed to fetch top tracks");
        }
        return await response.data;
    } catch (error) {
        console.error("Error fetching top tracks:", error);
    }
};



/**
 * Ruft die zuletzt gehörten Tracks des Benutzers von einem Backend-Service ab, basierend auf einem spezifizierten Limit.
 *
 * @async
 * @param {number} limit - Die maximale Anzahl von Tracks, die abgerufen werden sollen.
 * @throws {Error} Wird ausgelöst, wenn es ein Problem beim Abrufen der kürzlich gehörten Tracks gibt.
 */
export const fetchRecentTracks = async (limit = 20) => {
    try {
        console.log('Fetching recent tracks');

        const spotifyToken = getSpotifyTokenFromCookie();

        // Überprüfe den Wert des Tokens, der aus den Cookies extrahiert wurde.
        console.log('Token extracted from cookie:', spotifyToken);

        const response = await axios.get(`${baseURL}/recent-tracks/${limit}`, {
            headers: {
                'Authorization': `Bearer ${spotifyToken}`
            }
        });

        // Überprüfe den gesamten Antwortstatus
        console.log('Response status:', response.status);
        console.log('Response data:', response.data);

        if (response.status !== 200) {
            throw new Error("Failed to fetch recent tracks");
        }
        return await response.data;
    } catch (error) {
        console.error("Error fetching recent tracks:", error);
    }
};


export const fetchTopArtists = async (term = 'medium_term') => {
    try {
        console.log('Fetching top artists');

        const spotifyToken = getSpotifyTokenFromCookie();

        const response = await axios.get(`${baseURL}/top-artists/${term}`, {
            headers: {
                'Authorization': `Bearer ${spotifyToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error("Failed to fetch top artists");
        }
         return await response.data;
    } catch (error) {
        console.error("Error fetching top artists:", error);
    }
};

async function createPlaylist() {
    try {
        console.log('Creating playlist');
        const spotifyToken = getSpotifyTokenFromCookie();

        // Erstellen einer neuen Playlist und Abrufen der ID
        const response = await axios.post(`${baseURL}/createPlaylist`, {
            playlist_name: 'Webtech Projekt Playlist',
            description: 'Playlist that was created so you can dump your songs here',
            public: false
        }, {
            headers: {
                'Authorization': `Bearer ${spotifyToken}`
            }
        });

        const newPlaylistId = response.data.playlist_id;
        console.log('New playlist ID:', newPlaylistId);

        // Speichern der Playlist-ID im Backend
        const authToken = getAuthTokenFromCookie();
        if (authToken) {console.log('authToken:', authToken);}
        await axios.put(`${springURL}/api/user/updatePlaylistId`, {
            playlist_id: newPlaylistId
        }, {
            headers: {
                'Authorization': `${authToken}`
            }
        });
        console.log("kommt er bis hierhin?")
        return newPlaylistId;
    } catch (error) {
        console.error("Error creating and posting playlist ID:", error);
        throw error;
    }
}


export const addTracksToPlaylist = async (tracks) => {
    try {
        let postData;

        // Wenn tracks bereits eine playlist_id enthält
        if (tracks.playlist_id) {
            postData = {
                playlist_id: tracks.playlist_id,
                items: tracks.items
            };
        } else {
            // Wenn keine playlist_id vorhanden ist, erstellen wir eine neue Playlist
            const newPlaylistId = await createPlaylist();
            postData = {
                playlist_id: newPlaylistId,
                items: tracks.items
            };
        }

        // Token für die Autorisierung
        const spotifyToken = getSpotifyTokenFromCookie();

        // Axios POST-Request an das Backend senden
        await axios.post(`${baseURL}/addTracksToPlaylist`, postData, {
            headers: {
                'Authorization': `Bearer ${spotifyToken}`
            }
        });

        console.log('Tracks added to playlist:', postData.playlist_id);
    } catch (error) {
        console.error("Error adding tracks to playlist:", error);
    }
};

