
/**
 * Ruft das Spotify-Zugriffstoken ab, das in den Cookies gespeichert ist.
 * Die Funktion sucht nach einem Cookie mit dem Namen 'spotify_token' und
 * versucht, seinen Wert als JSON-Objekt zu interpretieren, um das
 * Zugriffstoken zu extrahieren.
 * Wenn der Cookie nicht gefunden wird oder das Token nicht interpretiert
 * werden kann, protokolliert die Funktion den Fehler und gibt null zurück.
 *
 * @returns {string|null} Das Zugriffstoken, falls es gefunden und erfolgreich interpretiert wurde, andernfalls null.
 */
export const getSpotifyTokenFromCookie = () => {
    const cookieName = 'spotify_token=';
    let cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(cookieName))
        ?.substring(cookieName.length);

    if (!cookieValue) {
        console.log('No token found in cookie');
    }

    //console.log(cookieValue);
    return cookieValue;
};

export function getAuthTokenFromCookie() {
    const cookieName = 'authToken=';
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(cookieName))
        ?.substring(cookieName.length);

    if (!cookieValue) {
        console.log('Kein authToken im Cookie gefunden');
        return null;
    }
    return cookieValue; // Der Cookie enthält bereits "Bearer TOKEN"
}