<template>
  <div class="auth-container">

    <!-- Login Formular -->
    <div v-if="isLoginView" class="login-container">
      <h1>Login</h1>
      <form @submit.prevent="handleLogin" class="form">
        <div class="form-group">
          <label for="username" class="form-label">Benutzername:</label>
          <input id="username" v-model="loginCredentials.username" type="text" required class="form-input">
        </div>
        <div class="form-group">
          <label for="password" class="form-label">Passwort:</label>
          <input id="password" v-model="loginCredentials.password" type="password" required class="form-input">
        </div>
        <button type="submit">Login</button>
      </form>
    </div>

    <!-- Registrierungsformular -->
    <div v-else class="registration-container">
      <h1>Registrierung</h1>
      <form @submit.prevent="handleRegistration" class="form">
        <!-- Benutzername-Feld -->
        <div class="form-group">
          <label for="newUsername" class="form-label">Benutzername:</label>
          <input id="newUsername" v-model="registrationCredentials.username" type="text" required class="form-input">
        </div>

        <!-- Passwort-Feld -->
        <div class="form-group">
          <label for="newPassword" class="form-label">Passwort:</label>
          <div class="input-icon-wrapper">
            <input id="newPassword" v-model="registrationCredentials.password" type="password" required class="form-input">
            <div class="icon-wrapper">
              <i v-if="registrationCredentials.password.length > 0" :class="['fa', isValidPassword ? 'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i>
            </div>
          </div>
        </div>

        <button type="submit" :disabled="!isValidUsername || !isValidPassword">Registrieren</button>
      </form>
    </div>

    <!-- Umschalten zwischen Login und Registrierung -->
    <div class="toggle-form-section">
      <span>Hier zwischen Registrierung und Login umschalten:</span>
      <button @click="toggleForm" class="toggle-form-button">
        {{ isLoginView ? 'Zur Registrierung' : 'Zum Login' }}
      </button>
    </div>
  </div>
  <div id="loginMarker" style="display: none;"></div>
</template>

<script>
import axios from 'axios';
import {computed, inject, reactive, ref} from 'vue';
import router from "@/router";

export default {
  name: 'LoginToJavaBackend',
  setup() {
    const setCurrentView = inject('setCurrentView');
    const isLoginView = ref(true); // Zustand für die Anzeige des Login- oder Registrierungsformulars

    const loginCredentials = reactive({
      username: '',
      password: ''
    });

    const registrationCredentials = reactive({
      username: '',
      password: ''
    });

    const baseUrl = process.env.VUE_APP_SPRING_API_URL;

    const isValidUsername = computed(() => {
      return registrationCredentials.username.length > 0;
    });

    const isValidPassword = computed(() => {
      const password = registrationCredentials.password;
      const hasNumber = /[0-9]/.test(password);
      const hasUpper = /[A-Z]/.test(password);
      const hasLower = /[a-z]/.test(password);
      const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      return password.length > 0 && hasNumber && hasUpper && hasLower && hasSpecial;
    });

    const handleLogin = async () => {
      try {
        const response = await axios.post(`${baseUrl}/api/auth/login`, loginCredentials);
        const bearerToken = response.data.token;
        document.cookie = `authToken=${bearerToken};path=/;max-age=3600`;
        if (setCurrentView) {
          setCurrentView('tracks');
        }
        await router.push('/');
        router.go(0);
      } catch (error) {
        console.error('Login-Fehler:', error);
      }
    };

    const handleRegistration = async () => {
      try {
        await axios.post(`${baseUrl}/api/user/register`, registrationCredentials);

        // Setzen der Login-Credentials auf die Registrierungsdaten für den automatischen Login nach Registrerung
        loginCredentials.username = registrationCredentials.username;
        loginCredentials.password = registrationCredentials.password;

        // Login nach der Registrierung
        await handleLogin();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          // Benutzername ist bereits vergeben
          alert('Benutzername ist bereits vergeben.');
        } else {
        console.error("Registrierungsfehler: ", error.message);
        }
      }
    };


    const toggleForm = () => {
      isLoginView.value = !isLoginView.value;
    };

    return {
      loginCredentials,
      registrationCredentials,
      isLoginView,
      toggleForm,
      handleLogin,
      handleRegistration,
      isValidPassword,
      isValidUsername
    };
  }
};
</script>
<style>

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.input-icon-wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  min-width: 30px; /* Stellt eine feste Breite für das Icon-Wrapper-Element sicher */
  display: flex;
  justify-content: center; /* Zentriert das Icon innerhalb des Icon-Wrappers */
}


.form-label {
  margin-bottom: 5px;
}

.form-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
}

.fa {
  font-size: 20px; /* Optional: Größe des Icons */
}

.form {
  max-width: 300px; /* Begrenzt die Breite der Formulare */
  margin: auto;
}
.toggle-form-section {
  margin-top: 20px;
  text-align: center;
}

.toggle-form-button {
  margin-left: 10px;
}

</style>